<template>
	<div class="dashboard">
		<div class="dashboard__body">
			<div class="boxes">
				<router-link
					to="/dashboard/inspections"
					v-if="
						checkModule({
							moduleName: 'inspeccion',
							pageName: 'listaInspecciones',
						})
					"
				>
					<div class="text">
						<v-icon>mdi-folder-text-outline</v-icon>
						<div class="text__title">Inspeciones HSEQ</div>
						<div class="text__description">Administración</div>
					</div>
				</router-link>
				<router-link
					to="/dashboard/inspections/revision"
					relative
					style="position: relative"
					v-if="
						checkModule({
							moduleName: 'aprobacion',
							pageName: 'listaRegistros',
						})
					"
				>
					<div class="text">
						<v-icon>mdi-archive-eye-outline</v-icon>
						<div class="text__title">Revisiones</div>
						<div class="text__description">Revisión de Inspecciones</div>
					</div>
					<v-btn color="warning" fab dark small absolute bottom right>
						<v-icon small>mdi-bell-outline</v-icon>
					</v-btn>
				</router-link>
				<router-link
					to="/dashboard/incidents"
					relative
					style="position: relative"
					v-if="
						checkModule({
							moduleName: 'incidencia',
							pageName: 'listaIncidencias',
						})
					"
				>
					<div class="text">
						<v-icon>mdi-archive-eye-outline</v-icon>
						<div class="text__title">Reporte de Fallas o Incidentes</div>
						<div class="text__description"></div>
					</div>
				</router-link>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'Dashboard',
	methods: {
		checkModule({ moduleName = '', pageName = '' }) {
			let found = false;
			const activities = this.$store.state.auth.activities || [];
			if (Array.isArray(activities)) {
				found = activities.some((activity) => {
					return (
						activity.module_name == moduleName &&
						activity.page_name == pageName
					);
				});
			}
			return found;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/dashboardHome.scss';
</style>
