var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard"},[_c('div',{staticClass:"dashboard__body"},[_c('div',{staticClass:"boxes"},[(
					_vm.checkModule({
						moduleName: 'inspeccion',
						pageName: 'listaInspecciones',
					})
				)?_c('router-link',{attrs:{"to":"/dashboard/inspections"}},[_c('div',{staticClass:"text"},[_c('v-icon',[_vm._v("mdi-folder-text-outline")]),_c('div',{staticClass:"text__title"},[_vm._v("Inspeciones HSEQ")]),_c('div',{staticClass:"text__description"},[_vm._v("Administración")])],1)]):_vm._e(),(
					_vm.checkModule({
						moduleName: 'aprobacion',
						pageName: 'listaRegistros',
					})
				)?_c('router-link',{staticStyle:{"position":"relative"},attrs:{"to":"/dashboard/inspections/revision","relative":""}},[_c('div',{staticClass:"text"},[_c('v-icon',[_vm._v("mdi-archive-eye-outline")]),_c('div',{staticClass:"text__title"},[_vm._v("Revisiones")]),_c('div',{staticClass:"text__description"},[_vm._v("Revisión de Inspecciones")])],1),_c('v-btn',{attrs:{"color":"warning","fab":"","dark":"","small":"","absolute":"","bottom":"","right":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-bell-outline")])],1)],1):_vm._e(),(
					_vm.checkModule({
						moduleName: 'incidencia',
						pageName: 'listaIncidencias',
					})
				)?_c('router-link',{staticStyle:{"position":"relative"},attrs:{"to":"/dashboard/incidents","relative":""}},[_c('div',{staticClass:"text"},[_c('v-icon',[_vm._v("mdi-archive-eye-outline")]),_c('div',{staticClass:"text__title"},[_vm._v("Reporte de Fallas o Incidentes")]),_c('div',{staticClass:"text__description"})],1)]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }